import React from 'react';

interface IProps {
  fill: string;
  className?: string;
}

const Shape = ({ fill, className }: IProps) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1509 501.4"
  >
    <path
      fill={fill}
      id="Union_1"
      d="M1603.3,35.7c40,31.6,62.6,78.7,73.6,126.3c17.7,76.5,6.1,162.5-48.9,221.9
    s-158.8,81.4-227.4,35.8c-18-12-33.6-28.1-54.5-34.8c-59.8-19.1-109.7,45.4-168.8,66.4c-38.3,13.7-84.1,7.4-116.6-15.9
    c-22.8-16.4-39.5-40.5-65.8-51.6c-54.7-23.1-112.9,20.3-169.7,38.5c-34.4,11-71.7,11-106.7,2.3c-49.9-9.9-97.5-35.1-148.1-29.9
    c-47.4,4.9-86.3,35.4-127.5,57.6c-41.3,22.2-97.1,35.4-134.2,7.5c-23.7-17.8-34.1-49-60.8-62.7c-32.3-16.6-73.3-0.1-100.8,22.7
    c-27.5,22.8-49.6,52.3-82.5,68c-46.7,22.3-106.7,10.4-147.3-20.6s-64.1-77.7-76-125.1c-19.1-76.2-9.2-162.4,44.7-222.7
    S43.2,35.5,112.7,80c18.3,11.7,34.1,27.5,55.2,33.9c60.2,18.1,108.8-47.1,167.5-69.1c38.1-14.3,84-8.8,116.9,14
    c23.1,16,40.3,39.9,66.7,50.5c55.2,22.2,112.5-22.2,169-41.2c33.7-11.4,70.4-12.1,105.1-4.4c52.8,9.4,102.3,38.5,155.8,33.9
    c47.5-4.1,86.9-34,128.6-55.5s97.7-33.8,134.3-5.3c23.4,18.2,33.2,49.5,59.7,63.7c32,17.2,73.3,1.3,101.3-21.1s50.6-51.5,83.7-66.7
    c16.1-7.2,33.6-10.8,51.3-10.7C1542,2.1,1576.9,14.9,1603.3,35.7z"
    />
  </svg>
);

export default Shape;
