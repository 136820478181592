import { graphql, useStaticQuery } from 'gatsby';

export const useLogo = () => {
  const { directusSetting: logo } = useStaticQuery(graphql`
    query getLogo {
      directusSetting {
        logo {
          title
          filename_disk
        }
        logo_primary {
          title
          filename_disk
        }
      }
    }
  `);
  return logo;
};
