import React from 'react';

import SectionWithCurve from '../section-with-curve/section-with-curve';
import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';

import styles from './landing-nutrients.module.scss';

interface INutrient {
  icon: string;
  text: string;
}

const LandingNutrients = () => {
  const directus = useBarLandingText();
  return (
    <SectionWithCurve>
      <div className={styles.nutrients}>
        {directus.nutrients.map((nutrient: INutrient) => (
          <div className={styles.item} key={nutrient.icon}>
            <img src={`${process.env.GATSBY_MEDIA_URL}${nutrient.icon}`} alt="" />
            <p>{nutrient.text}</p>
          </div>
        ))}
      </div>
    </SectionWithCurve>
  );
};

export default LandingNutrients;
