import { graphql, useStaticQuery } from 'gatsby';

export const useBarLandingText = () => {
  const { directusBarLanding } = useStaticQuery(graphql`
    query BarLandingText {
      directusBarLanding {
        page_title
        page_description
        hero_tagline
        hero_header
        image_1 {
          filename_disk
        }
        image_2 {
          filename_disk
        }
        image_3 {
          filename_disk
        }
        color_1
        color_2
        color_3
        hero_benefits {
          text
        }
        products_header
        products_text
        products {
          color
          secondaryColor
          header
          image
          filename_disk
          name
          text
          priceText
          identifier
          url
        }
        parallax_image_1 {
          filename_disk
        }
        parallax_image_2 {
          filename_disk
        }
        parallax_image_3 {
          filename_disk
        }
        snack_header
        snack_text_1
        snack_text_2
        snack_text_3
        nutrients {
          icon
          text
        }
        competitors_header
        competitors_image {
          filename_disk
        }
        table_header
        rows {
          one
          two
          tree
        }
        faq_header
        faq_description
        faqs {
          question
          answer
        }
        reviews {
          review
          reviewerName
        }
        banner_offer_title
        banner_offer_description
        banner_offer_image {
          filename_disk
        }
        banner_offer_intro_label
        banner_offer_old_price_text
        banner_offer_new_price_text
        banner_offer_action_button_text
        banner_offer_sku
      }
    }
  `);
  return directusBarLanding;
};
