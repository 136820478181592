import React, { useContext } from 'react';

import { COOKIES } from '@constants/cookies';
import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';
import { getCookie } from '@services/cookies';
import { GlobalContext } from '@store/global-state';
import { useAddToBasket } from '../../../shop/hooks/useAddToBasket';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

import styles from './banner-product-offer.module.scss';
import { OUT_OF_STOCK } from '@src/constants/messages';

const BannerProductOffer = () => {
  const directus = useBarLandingText();
  const { setHasUserAddedProductToBasket, setShowBasket } = useContext(GlobalContext);

  const { dataPlans, loading: plansLoading, isAvailable, price } = useGetProductPlansData(
    'mixed-bars-6-pack'
  );

  const onCompletedCallback = () => {
    setHasUserAddedProductToBasket(true);
    setShowBasket(true);
  };

  const { addItemToBasket, loading } = useAddToBasket(onCompletedCallback);

  const handleAddToBasket = () => {
    addItemToBasket({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        sku: dataPlans?.product_plans ? dataPlans?.product_plans[0]?.sku : '',
        quantity: 1,
      },
    });
  };

  return (
    <div className={styles.bannerOfferWrapper}>
      <div className={styles.bannerOffer}>
        <div className={styles.titleAndDescription}>
          <h2>{directus.banner_offer_title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: directus.banner_offer_description,
            }}
            className={styles.bannerOfferDescription}
          />
        </div>
        <div className={styles.imageWrapper}>
          <img
            src={`${process.env.GATSBY_MEDIA_URL}${directus.banner_offer_image.filename_disk}`}
          />
        </div>
        {price && !plansLoading && isAvailable ? (
          <div className={styles.priceAndActionButton}>
            <h5 className={styles.introOffer}>
              <div>{directus.banner_offer_intro_label}</div>
              <div>{price} for 6 bars</div>
            </h5>
            <button className={styles.addToBasket} onClick={handleAddToBasket} disabled={loading}>
              {directus.banner_offer_action_button_text}
            </button>
          </div>
        ) : null}
        {!plansLoading && !isAvailable && (
          <div className={styles.priceAndActionButton}>
            <h4>{OUT_OF_STOCK}</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerProductOffer;
