import React, { FC } from 'react';

import { useLogo } from '@hooks/directusHooks/useLogo';
import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';

import styles from './vitl-and-competitors.module.scss';

interface IRow {
  one: string;
  two: string;
  tree: string;
}

const VitlAndCompetitors: FC = () => {
  const logo = useLogo();
  const directus = useBarLandingText();

  return (
    <div className={styles.wrapper}>
      <div className={styles.vitlAndCompetitors}>
        <div className={styles.imageWrap}>
          <img
            src={`${process.env.GATSBY_MEDIA_URL}${directus.competitors_image.filename_disk}`}
            alt="vitl-and-competitors"
          />
        </div>
        <div className={styles.differences}>
          <table>
            <tr>
              <th />
              <th className={styles.logoWrap}>
                <img
                  className={styles.logo}
                  src={`${process.env.GATSBY_MEDIA_URL}${logo.logo_primary.filename_disk}`}
                  alt=""
                />
              </th>
              <th>{directus.table_header}</th>
            </tr>
            {directus.rows.map((row: IRow) => (
              <tr>
                <td className={styles.leftColumn}>{row.one}</td>
                <td className={styles.centerColumn}>{row.two}</td>
                <td className={styles.rightColumn}>{row.tree}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default VitlAndCompetitors;
