import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import BarsLanding from '@features/bars/bars-landing';
import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';

const Bars = () => {
  const t = useBarLandingText();
  return (
    <Layout>
      <SEO title={t.page_title} description={t.page_description} />
      <BarsLanding />
    </Layout>
  );
};

export default Bars;
