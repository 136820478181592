import React from 'react';
import { Parallax } from 'react-parallax';

import styles from './landing-parallax.module.scss';

interface IProps {
  image: string;
}

const LandingParallax = ({ image }: IProps) => {
  return (
    <Parallax bgImage={image} strength={-150} bgImageStyle={{ objectFit: 'cover' }}>
      <div className={styles.parallax}></div>
    </Parallax>
  );
};

export default LandingParallax;
