import React from 'react';

import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';

import styles from './landing-hero.module.scss';

const Icon = () => <img src="/images/icon-compare-check.svg" width="16px" alt="check" className={styles.icon} />;

const LandingHero = () => {
  const directus = useBarLandingText();
  return (
    <div className={styles.landingHero}>
      <div className={styles.left}>
        <div className={styles.item} style={{ background: directus.color_1 }}>
          <img src={`${process.env.GATSBY_MEDIA_URL}${directus.image_1.filename_disk}`} alt="" />
        </div>
        <div className={styles.item} style={{ background: directus.color_2 }}>
          <img src={`${process.env.GATSBY_MEDIA_URL}${directus.image_2.filename_disk}`} alt="" />
        </div>
        <div className={styles.item} style={{ background: directus.color_3 }}>
          <img src={`${process.env.GATSBY_MEDIA_URL}${directus.image_3.filename_disk}`} alt="" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          {directus.hero_tagline ? <p className={styles.subheader}>{directus.hero_tagline}</p> : null}
          <h1> {directus.hero_header}</h1>
          <div className={styles.explain}>
            {directus.hero_benefits.map((item: any) => (
              <span>
                <Icon />
                {item.text}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHero;
