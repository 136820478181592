import React, { useContext } from 'react';

import LandingProduct from './landing-product';
import { IProduct } from '@features/shop/interfaces/shop';
import { GlobalContext } from '@store/global-state';
import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';
import { IBarProduct } from '../../interfaces/bar';

import styles from './landing_products.module.scss';

const LandingProducts = () => {
  const directus = useBarLandingText();
  const { setShowShopModal, setCurrentProductAndPlanData } = useContext(GlobalContext);

  const addToBasket = (product: IProduct, plan: any) => {
    setCurrentProductAndPlanData({ product, plan });
    setShowShopModal(true);
  };

  return (
    <>
      <div className={styles.headerWrap}>
        <h2>{directus.products_header}</h2>
        <hr />
        <p>{directus.products_text}</p>
      </div>
      <div className={styles.productWrap}>
        {directus.products.map((one: IBarProduct) => (
          <LandingProduct key={one.header} data={one} addToBasket={addToBasket} />
        ))}
      </div>
    </>
  );
};

export default LandingProducts;
