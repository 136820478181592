import React from 'react';

import Shape from '../shape/shape';

import styles from './section-with-curve.module.scss';

interface IProps {
  children: React.ReactNode;
  curveColor?: string;
}

const SectionWithCurve = ({ children, curveColor = '#FEFAED' }: IProps) => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <>{children}</>
      </div>
      <div className={styles.shapeWrap}>
        <Shape fill={curveColor} className={styles.imageShape} />
      </div>
    </div>
  );
};

export default SectionWithCurve;
