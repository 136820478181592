import React from 'react';
import { Link } from 'gatsby';

import Shape from '../shape/shape';
import { IProduct } from '@features/shop/interfaces/shop';
import Image from '@components/image/image';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

import styles from './landing_products.module.scss';
import { OUT_OF_STOCK } from '@src/constants/messages';

interface IProps {
  data: any;
  addToBasket: (product: IProduct, dataPlans: any) => void;
}

const LandingProduct = ({ data, addToBasket }: IProps) => {
  const { dataPlans, loading, price, isAvailable } = useGetProductPlansData(data.identifier);

  const addToBasketHandler = () => {
    addToBasket(data, dataPlans);
  };

  const renderPrice = () => {
    return <p className={styles.price}>from {price} for 15 bars</p>;
  };

  return (
    <div className={styles.productItem} style={{ background: data.color }}>
      <div className={styles.header} style={{ background: data.secondaryColor }}>
        <Shape fill={data.secondaryColor} className={styles.curveShape} />
        <h4>{data.header}</h4>
      </div>
      <div className={styles.body}>
        <Image filename={data.image} width={600} />
        <p className={styles.subHeader}>{data.name}</p>
        <p>{data.text}</p>
        {loading && (
          <p className={styles.price}>
            <span className={styles.loader} />
          </p>
        )}
        {!loading && !isAvailable && <p className={styles.price}>{OUT_OF_STOCK}</p>}
        {!loading && isAvailable && renderPrice()}
        <div className={styles.footer}>
          {!loading && isAvailable && (
            <button onClick={addToBasketHandler} className={styles.buttonPrimary}>
              Add to basket
            </button>
          )}
          <Link to={data.url} className={styles.buttonSecoundary}>
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingProduct;
