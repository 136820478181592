import React from 'react';

import JoinTheCommunity from '@components/join-the-community/join-the-community';
import LandingHero from './components/landing-hero/landing-hero';
import LandingProducts from './components/landing_products/landing_products';
import LandingSnackGames from './components/landing-snack-game/landing-snack-game';
import LandingNutrients from './components/landing-nutrients/landing-nutrients';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
import LandingCompetitorSection from './components/landing-competitor-section/landing-competitor-section';
import Reviews from '@components/reviews/reviews';
import LandingParallax from './components/landing-parallax/landing-parallax';
import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';

import styles from './bar.module.scss';
import BannerProductOffer from './components/landing_products/BannerProductOffer';
import SectionWithCurve from './components/section-with-curve/section-with-curve';
import { useMediaQuery } from 'react-responsive';

const BarsLanding = () => {
  const directus = useBarLandingText();

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <div className={styles.bars}>
      <LandingHero />
      <SectionWithCurve curveColor={isMobile ? '#ffd326' : '#fefaed'}>
        <div className={styles.landingContentWrapper}>
          <LandingProducts />
          <BannerProductOffer />
        </div>
      </SectionWithCurve>
      <LandingParallax image={`${process.env.GATSBY_MEDIA_URL}${directus.parallax_image_1.filename_disk}`} />
      <LandingSnackGames />
      <LandingParallax image={`${process.env.GATSBY_MEDIA_URL}${directus.parallax_image_2.filename_disk}`} />
      <LandingNutrients />
      <LandingParallax image={`${process.env.GATSBY_MEDIA_URL}${directus.parallax_image_3.filename_disk}`} />
      <LandingCompetitorSection />
      <StillHaveQuestion accordion={directus.faqs} description={directus.faq_description} />
      <Reviews title="Why our customers love Vitl" items={directus.reviews} showReview hideTrustPilot />
      <JoinTheCommunity />
    </div>
  );
};

export default BarsLanding;
