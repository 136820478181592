import React from 'react';

import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';
import SectionWithCurve from '../section-with-curve/section-with-curve';

import styles from './landing-snack-game.module.scss';

const LandingSnackGames = () => {
  const directus = useBarLandingText();
  return (
    <SectionWithCurve>
      <div className={styles.snack}>
        <h2>{directus.snack_header}</h2>
        <hr />
        <p>{directus.snack_text_1}</p>
        <p>{directus.snack_text_2}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: directus.snack_text_3,
          }}
        />
      </div>
    </SectionWithCurve>
  );
};

export default LandingSnackGames;
