import React from 'react';

import { useBarLandingText } from '@hooks/directusHooks/useBarLandingText';
import VitlAndCompetitors from '../vitl-and-competitors/VitlAndCompetitors';

import styles from './landing-competitor-section.module.scss';

const LandingCompetitorSection = () => {
  const directus = useBarLandingText();
  return (
    <div className={styles.competitorsSection}>
      <div className={styles.container}>
        <h2>{directus.competitors_header}</h2>
        <hr />
        <VitlAndCompetitors />
      </div>
    </div>
  );
};

export default LandingCompetitorSection;
